import { componentRoutes } from "./componentRoutes";
import TestLayout from "../pages/layouts/test_layout";
import Dashboard from "../pages/admin/dashboard";
import Blog from "../pages/admin/blog";
import AddBlog from "../pages/admin/add_blog";
import EditBlog from "../pages/admin/edit_blog";
import ViewBlog from "../pages/admin/view_blog";

const adminRoutes = [
    {
        path: componentRoutes.adminDashboard,
        component: Dashboard,
        layout: TestLayout
    },
    {
        path: componentRoutes.blog,
        component: Blog,
        layout: TestLayout
    },
    {
        path: componentRoutes.addBlog,
        component: AddBlog,
        layout: TestLayout
    },
    {
        path: componentRoutes.editBlog,
        component: EditBlog,
        layout: TestLayout
    },
    {
        path: componentRoutes.viewBlog,
        component: ViewBlog,
        layout: TestLayout
    },
];

const rootRoutes = [
    {
        path: componentRoutes.root,
        component: Dashboard,
        layout: TestLayout
    },
]

export const pageRoutes = [
    ...rootRoutes,
    ...adminRoutes
];