export const ACTIONS = {
  EDIT: "edit",
  DELETE: "delete",
  VIEW: "view",
};

export const DEFAULT_ACTION = [
  {
    value: ACTIONS.EDIT,
    icon: "Pencil",
    color: "green",
    label: "Edit",
  },
  {
    value: ACTIONS.DELETE,
    icon: "Trash2",
    color: "red",
    label: "Delete",
  },
];

export const VIEW = [
  {
    value: ACTIONS.VIEW,
    icon: "Eye",
    color: "sky",
    label: "View"
  }
]