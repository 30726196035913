import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { HrmFormInput } from "../components/hrm_input";
import { Editor } from 'primereact/editor';
import { useBlog } from "../../contexts";
import { useParams } from "react-router-dom";
import HrmLoader from "../components/hrm_loader";
import { uploadImageToS3 } from "../../utils/fileUpload";
        


const EditBlog = () => {

    // const [text, setText] = useState();
    // console.log(text);
    const [selectedThumbnail, setSelectedThumbnail] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);

    const { getBlog, onUpdateBlog, blogLoader, blogDetail } = useBlog();
    console.log(blogDetail);
    
    const { blog_id } = useParams();
    const {
        handleSubmit,
        register,
        reset, 
        setValue, 
        watch
      } = useForm();

    useEffect(() => {
        getBlog(blog_id)
    }, [getBlog, blog_id]);

    useEffect(() => {
        if(blogDetail) {
                
                setValue("id",blogDetail.id);
                setValue("title",blogDetail.title);
                setValue("short_desc",blogDetail.short_desc);
                setValue("long_desc", blogDetail.long_desc);
                setValue("thumb_nail", blogDetail.thumb_nail);
                setValue("image", blogDetail.image);
                setValue("meta_tag", blogDetail.meta_tag);
                setValue("meta_desc", blogDetail.meta_desc);
    
      }}, [blogDetail, setValue]);


      const handleThumbnailChange = (event) => {
        const file = event.target.files[0];
        setSelectedThumbnail(file ? URL.createObjectURL(file) : null);
      };
    
      const handleImageChange = (event) => {
        const file = event.target.files[0];
        setSelectedImage(file ? URL.createObjectURL(file) : null);
      };
    
      const extractFilename = (url) => {
        return url ? url.split('/').pop() : null;
      };
      

      const onSubmit = async (values) => {
        let s3thumbnail, s3image;
      
        // Handle thumbnail
        if (values.thumb_nail instanceof FileList && values.thumb_nail.length > 0) {
          const thumbnail = values.thumb_nail[0];
          s3thumbnail = await uploadImageToS3(thumbnail);
        } else {
          s3thumbnail = extractFilename(blogDetail.thumb_nail);
        }
      
        // Handle image
        if (values.image instanceof FileList && values.image.length > 0) {
          const image = values.image[0];
          s3image = await uploadImageToS3(image);
        } else {
          s3image = extractFilename(blogDetail.image);
        }
      
        // Create the payload
        const payload = {
          ...values,
          blog_id: blog_id,
          thumb_nail: s3thumbnail,
          image: s3image,
        };
      
        
        await onUpdateBlog(payload);
      };
      
      
  //     const onSubmit = async (values) => {
  //       const thumbnail = values.thumb_nail?.[0];
  //       const image = values.image?.[0];

  //       // If no new file is provided, retain the original URLs
  // const s3thumbnail = thumbnail ? await uploadImageToS3(thumbnail) : extractFilename(blogDetail.thumb_nail);
  // const s3image = image ? await uploadImageToS3(image) : extractFilename(blogDetail.image);

  //       const payload = {
  //           ...values,
  //           blog_id: blog_id,
  //           image: s3image,
  //           thumb_nail: s3thumbnail
  //       }

  //       console.log(payload);
        
  //       // await onUpdateBlog(payload)
  //     }

  const handleReset = () => {
    reset();
  }
    return (
        <div>
            <div className="col-span-12 md:order-1 xl:col-span-8 2xl:col-span-6">
        <h5 className="mb-2">Edit Blog</h5>
    </div>
    {blogLoader ? (
      <HrmLoader />
    ) : (
<div className="grid grid-cols-1 xl:grid-cols-12 gap-x-5">
                    <div className="xl:col-span-12">
                        <div className="card">
                            <div className="card-body">
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="flex gap-3">
                                        <div className="flex-1">
                                            <HrmFormInput label={"Title"} {...register("title")}/>
                                            <HrmFormInput label={"Short Description"} {...register("short_desc")} />
                                            <HrmFormInput label={"Long Description"} type="textarea" {...register("long_desc")} />
                                            <HrmFormInput label={"Thumbnail"} type="file" {...register("thumb_nail")} onChange={handleThumbnailChange} /> 
                                            {/* {blogDetail?.thumb_nail && ( */}
                                              <div className="mt-4">
                                                <img
                                                  src={selectedThumbnail || blogDetail?.thumb_nail} // Render the URL or base64 string
                                                  alt="img"
                                                  style={{ maxWidth: "200px", borderRadius: "8px" }}
                                                />
                                              </div>
                                            {/* )} */}
                                        <HrmFormInput label={"Image"} type="file" {...register("image")} onChange={handleImageChange} />
                                        {/* {blogDetail?.image && ( */}
                                              <div className="mt-4">
                                                <img
                                                  src={selectedImage || blogDetail?.image} // Render the URL or base64 string
                                                  alt="img"
                                                  style={{ maxWidth: "200px", borderRadius: "8px" }}
                                                />
                                              </div>
                                            {/* )} */}
                                        <HrmFormInput label={"Meta tag"} {...register("meta_tag")}/>
                                        <HrmFormInput label={"Meta Description"} type="textarea" {...register("meta_desc")} />
                                          
                                        </div>
                                        
                                    </div>                                    
                                    <div className="flex justify-center gap-2 mt-4">
                                        <button type="reset" className="text-red-500 bg-white btn hover:text-red-500 hover:bg-red-100 focus:text-red-500 focus:bg-red-100 active:text-red-500 active:bg-red-100 dark:bg-zink-700 dark:hover:bg-red-500/10 dark:focus:bg-red-500/10 dark:active:bg-red-500/10" onClick={handleReset}>Reset</button>
                                        <button type="submit" className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20">Edit Blog</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                    
                    
                </div>
    )}
            
        </div>
    )
};

export default EditBlog;