const AUTH_APIS = {
    LOGIN: "/admin_auth/login",
    SEND_OTP: "/employee_auth/send_otp",
    ADMIN_REGISTER: "/admin_auth/register",
    CHANGE_PASSWORD: "/admin_auth/change_password",
    LOGOUT: "/admin_auth/logout",
    LOGIN_USER: "/common/employee_get_details"
};

const ADMIN_APIS = {

    BLOG_LIST: "/common/blog_listing",
    ADD_BLOG: "/common/add_blog",
    GET_BLOG: "/common/blog_details",
    DELETE_BLOG: "/common/delete_blog",
    EDIT_BLOG: "/common/edit_blog",
    COUNT: "/common/dashbord_count",

    ADD_EMPLOYEE: "/admin_employee/add_employee",
    EMPLOYEE_LIST: "/admin_employee/emp_listing",
    UPDATE_EMPLOYEE: "/admin_employee/edit_employee",
    DELETE_EMPLOYEE: "/admin_employee/delete_employee",
    EMPLOYEE_DETAILS: "/admin_employee/emp_details",

    DASHBOARD_COUNT: "/admin_dashboard/dashboard_count",
    ADD_ANNOUNCEMENT: "/admin_dashboard/add_announcement",
    ANNOUNCEMENT_LIST: "/admin_dashboard/announcement_listing",
    
    LEAVE_LIST: "/admin_emp_leave/emp_leave_listing",
    UPDATE_LEAVE_STATUS: "/admin_emp_leave/approve_reject_leave",
    ADMIN_LEAVE_ADD: "/admin_emp_leave/admin_emp_leave_add",
    LEAVE_BALANCE_ADD: "/admin_emp_leave/admin_add_leave",
    LEAVE_BALANCE: "/employee_leave/leave_balance",
    
    HOLIDAYS_LIST: "/admin_holidays/holidays_listing",
    ADD_HOLIDAY: "/admin_holidays/add_holidays",
    EDIT_HOLIDAY: "/admin_holidays/edit_holidays",
    DELETE_HOLIDAY: "/admin_holidays/delete_holidays",
    
    EMPLOYEE_ATTENDANCE: "/admin_emp_attendence/admin_emp_attedence_list",
    
    DEPARTMENT_LIST: "/admin_department/departments_listing",
    ADD_DEPARTMENT: "/admin_department/add_departments",
    EDIT_DEPARTMENT: "/admin_department/edit_departments",
    DELETE_DEPARTMENT: "/admin_department/delete_departments",
    
    ADD_ATTENDANCE: "/admin_emp_attendence/admin_add_attendence",
    
    ADD_BANK_DETAILS: "/admin_auth/add_bank_details",
    LIST_BANK_DETAILS: "/admin_auth/bank_details_listing",
    DELETE_BANK_DETAILS: "/admin_auth/delete_bank_details",
    
    SALARY_COUNT: "/admin_emp_attendence/salary_count",
    
    PROJECT_LIST: "/admin_projects/projects_listing",
    
    ADD_DAILY_TASK: "/admin_daily_task/add_daily_task",
    DAILY_TASK_LIST: "/admin_daily_task/daily_task_listing",
    EDIT_DAILY_TASK: "/admin_daily_task/edit_daily_task",
    DELETE_DAILY_TASK: "/admin_daily_task/delete_daily_task",
    UPDATE_DAILY_TASK_STATUS: "admin_daily_task/approve_reject_task",
};  


export const APIS = { 
    ...AUTH_APIS,
    ...ADMIN_APIS
 }