import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useBlog } from "../../contexts";
import { ACTIONS } from "../../constants/actions";
import { adminBlogColumns } from "../../constants/data_columns/blogs";
import HrmLoader from "../components/hrm_loader";
import { HRMTable } from "../components/hrm_table";
import NoData from "../../assets/No-Search-Result.png";
import DeleteModal from "../modals/delete_model";
import { useNavigate } from "react-router-dom";
import { componentRoutes } from "../../constants/componentRoutes";

const Blog = () => {

  const [blogData, setBlogData] = useState([]);
  const [selectedId, setSelectedId] = useState(null);
  const [isDeleteFormVisible, setDeleteFormVisible] = useState(false);
  const [blogId, setBlogId] = useState(null);
  const [viewId, setViewId] = useState(null);

    const { onLoadBlog, blogs, blogLoader, onDeleteBlog } = useBlog();
    
    const navigate = useNavigate();
    

    useEffect(() => {
      onLoadBlog();
    }, [onLoadBlog]);

    useEffect(() => {
      if(blogs?.length > 0) {
        setBlogData(
          blogs.map((item) => {
            return {
              id: item.id,
              title: item.title,
              short_desc: item.short_desc,
              long_desc: item.long_desc,
              thumb_nail: item.thumb_nail,
              image: item.image,
              meta_tag: item.meta_tag,
              meta_desc: item.meta_desc,
            }
          })
        )
      }
    }, [blogs]);

    useEffect(() => {
      if (blogId !== null) {
          navigate(componentRoutes.editBlog.replace(':blog_id', blogId));
      }
  }, [navigate, blogId]);

  useEffect(() => {
    if (viewId !== null) {
        navigate(componentRoutes.viewBlog.replace(':id', viewId));
    }
}, [navigate, viewId]);

    const handleDelete = async () => {
      if (!selectedId) return;
    
      try {
        
        const response = await onDeleteBlog(selectedId);
      //   if (response.data.success) {
          // toast.success("Department deleted successfully!");
          // Optionally refresh or update the data here
      //   }
      } catch (error) {
      //   toast.error("Failed to delete department. Please try again.");
      //   console.error(error);
      } finally {
        setDeleteFormVisible(false);
        setSelectedId(null);
      }
    };

    const onSelectAction = useCallback(
      async (row, value) => {
           
           if (ACTIONS.EDIT === value) {
              //  setInitialValues(row);
              setBlogId(row.id);
           } else if (ACTIONS.DELETE === value) {
              setSelectedId(row.id);
              setDeleteFormVisible(true)

           } else if (ACTIONS.VIEW === value) {
              setViewId(row.id);
           }
           else {
               console.log("Unhandled action:", value);
           }
       },
       []
   );
   
   const columns = useMemo(() => {
       return adminBlogColumns({ onSelectAction });
   }, [onSelectAction]);

    return (
        <div>
          <div className="col-span-12 md:order-1 xl:col-span-8 2xl:col-span-6">
        <h5 class="mb-2">Blogs</h5>
    </div>
            <div className="card">
            <div className="card-body">
          {blogLoader ? (
                <HrmLoader />
            ) : blogData?.length > 0 ? (
              <>
                    <HRMTable data={blogData} columns={columns} />
                    {isDeleteFormVisible && 
              <DeleteModal
            isVisible={isDeleteFormVisible}
            onClose={() => {
                setDeleteFormVisible(false)
                setSelectedId(null);
            }}
            onConfirm={handleDelete}
            // onConfirm={() => handleDelete(employeeId)}    // Call delete user function
            title="Delete Blog"
            description="Are you sure you want to delete this blog?"
          />
          }
              </>
            ) : (
                <img src={NoData} alt="No Data Available" className="w-200 h-200 mx-auto" />
            ) }
            </div>
            </div>
        </div>
    )
};

export default Blog;