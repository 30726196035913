import React from "react";
import Compose from "./compose";

import {
  BlogProvider
} from "../src/contexts";

export const AppWrapper = ({ children }) => {
    const allProviders = [
      BlogProvider
    ];

    return (
        <React.Fragment>
          <Compose providers={allProviders}>{children}</Compose>  
        </React.Fragment>
      );
};