
const rootRoutes = {
    root: "/",
};

const adminRoutes = {
    adminDashboard: "/dashboard",
    blog: "/blog",
    addBlog: "/add-blog",
    editBlog: "/editblog/:blog_id",
    viewBlog: "/viewblog/:id",
};

export const componentRoutes = {
    ...rootRoutes,
    ...adminRoutes,
};