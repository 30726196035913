import React, { useEffect, useState, useMemo } from "react";
import { useTable } from "react-table";
import { Row, Col } from "react-bootstrap";
import { ChevronRight, ChevronLeft } from "lucide-react";

export const HRMTable = ({ columns, data, onUpdateFilter, filters }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const validData = Array.isArray(data) ? data : [];
  const totalPages = Math.ceil(validData.length / itemsPerPage);

  const onPageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const onItemsPerPageChange = (e) => {
    setItemsPerPage(Number(e.target.value));
    setCurrentPage(1); // Reset to the first page on items-per-page change
  };

  const paginatedData = useMemo(() => {
    return validData.slice(
      (currentPage - 1) * itemsPerPage,
      currentPage * itemsPerPage
    );
  }, [validData, currentPage, itemsPerPage]);

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } =
    useTable({ data: paginatedData || [], columns });

  // Stabilize `rows` with useMemo
  const memoizedRows = useMemo(() => rows, [rows]);

  const [datas, setDatas] = useState([]);

  useEffect(() => {
    const rowsData = memoizedRows.map((ele, ind) => {
      const obj = { ...ele };
      const originals = { ...obj.original };

      const idd = currentPage === 1
        ? ind + 1
        : (currentPage - 1) * itemsPerPage + ind + 1;

      obj.idd = idd;
      originals.idd = idd;

      obj.original = originals;
      return obj;
    });

    setDatas(rowsData);
  }, [memoizedRows, currentPage, itemsPerPage]);

  const generatePagination = () => {
    const pagination = [];
    const totalVisibleButtons = 3;
    const delta = Math.floor(totalVisibleButtons / 2);

    pagination.push("prev");

    if (currentPage > delta + 1) {
      pagination.push(1);
      if (currentPage > delta + 2) {
        pagination.push("...");
      }
    }

    const start = Math.max(1, currentPage - delta);
    const end = Math.min(totalPages, currentPage + delta);

    for (let i = start; i <= end; i++) {
      pagination.push(i);
    }

    if (currentPage < totalPages - delta) {
      if (currentPage < totalPages - delta - 1) {
        pagination.push("...");
      }
      pagination.push(totalPages);
    }

    pagination.push("next");

    return pagination;
  };

  return (
    <React.Fragment>
      <Row className="overflow-x-auto bg-white shadow-md rounded-md dark:bg-gray-800">
        <Col sm={12}>
          <table
            className="w-full border-collapse whitespace-nowrap text-sm text-gray-700 dark:text-gray-300"
            {...getTableProps()}
          >
            <thead className="bg-gray-100 dark:bg-gray-700 text-gray-800 dark:text-gray-200">
              {headerGroups.map((headerGroup, index) => (
                <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                  {headerGroup.headers.map((column, index) => (
                    <th
                      key={index}
                      {...column.getHeaderProps()}
                      className="px-4 py-3 text-left font-semibold uppercase tracking-wide border-b border-gray-200 bg-slate-100 rounded dark:border-gray-600"
                    >
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()} className="divide-y divide-gray-200 dark:divide-gray-700">
              {datas.map((row, index) => {
                prepareRow(row);
                return (
                  <tr
                    key={index}
                    {...row.getRowProps()}
                    className="hover:bg-gray-50 dark:hover:bg-gray-700"
                  >
                    {row.cells.map((cell, index) => (
                      <td
                        key={index}
                        className="px-4 py-3 text-sm text-gray-600 dark:text-gray-400 border-b border-gray-200 dark:border-gray-700"
                      >
                        {cell.render("Cell")}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Col>
      </Row>

      {/* Pagination */}
      {validData.length > 0 && (
        <div className="flex flex-col items-center mt-5 md:flex-row">
          <div className="flex items-center gap-2 mb-4 md:mb-0">
            <label htmlFor="itemsPerPage" className="text-slate-500 dark:text-zinc-200">
              Items per page:
            </label>
            <select
              id="itemsPerPage"
              value={itemsPerPage}
              onChange={onItemsPerPageChange}
              className="border border-slate-400 rounded px-2 py-1"
            >
              {[5, 10, 20, 50].map((count) => (
                <option key={count} value={count}>
                  {count}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-4 grow md:mb-0 ms-3">
            <p className="text-slate-500 dark:text-zinc-200">
              {/* Showing <b>{Math.min(itemsPerPage, data.length - (currentPage - 1) * itemsPerPage)}</b> of <b>{data.length}</b> Results */}
              Showing <b>{Math.min(currentPage * itemsPerPage, data.length)}</b> of <b>{data.length}</b> Results
            </p>
          </div>
          {/* Pagination Buttons */}
          <ul className="flex flex-wrap items-center gap-2 shrink-0">
            {generatePagination().map((item, index) => (
              <li key={index}>
                {/* Render Pagination Buttons */}
                {item === 'prev' ? (
        <button
          disabled={currentPage === 1}
          className={`inline-flex items-center justify-center bg-white dark:bg-zinc-700 h-8 px-3 transition-all duration-150 ease-linear border rounded border-slate-200 dark:border-zinc-500 ${
            currentPage === 1
              ? 'text-gray-400 cursor-not-allowed'
              : 'text-slate-500 dark:text-zinc-200 hover:text-custom-500 dark:hover:text-custom-500 hover:bg-custom-50 dark:hover:bg-custom-500/10 focus:bg-custom-50 dark:focus:bg-custom-500/10 focus:text-custom-500 dark:focus:text-custom-500'
          }`}
          onClick={() => onPageChange(currentPage - 1)}
        >
          <ChevronLeft className="mr-1 size-4 rtl:rotate-180" /> Prev
        </button>
      ) : item === 'next' ? (
        <button
          disabled={currentPage === totalPages}
          className={`inline-flex items-center justify-center bg-white dark:bg-zinc-700 h-8 px-3 transition-all duration-150 ease-linear border rounded border-slate-200 dark:border-zinc-500 ${
            currentPage === totalPages
              ? 'text-gray-400 cursor-not-allowed'
              : 'text-slate-500 dark:text-zinc-200 hover:text-custom-500 dark:hover:text-custom-500 hover:bg-custom-50 dark:hover:bg-custom-500/10 focus:bg-custom-50 dark:focus:bg-custom-500/10 focus:text-custom-500 dark:focus:text-custom-500'
          }`}
          onClick={() => onPageChange(currentPage + 1)}
        >
          Next <ChevronRight className="ml-1 size-4 rtl:rotate-180" />
        </button>
      ) : item === '...' ? (
        <span className="inline-flex items-center justify-center bg-white dark:bg-zinc-700 h-8 px-3 border rounded border-slate-200 dark:border-zinc-500 text-slate-500 dark:text-zinc-200">
          ...
        </span>
      ) : (
        <button
          className={`inline-flex items-center justify-center size-8 transition-all duration-150 ease-linear border rounded hover:text-black-500 hover:bg-custom-50 focus:bg-custom-500 focus:text-custom-500 ${
            currentPage === item
              ? 'bg-custom-500 text-white hover:bg-sky-600 focus:bg-custom-500'
              : 'bg-white text-custom-500 hover:bg-sky-600 focus:bg-custom-500'
          }`}
          onClick={() => onPageChange(item)}
        >
          {item}
        </button>
      )}
              </li>
            ))}
          </ul>
        </div>
      )}
    </React.Fragment>
  );
};
