import React from "react";
import { Search } from "lucide-react";

export const HrmFormInput = React.forwardRef(
    ({ label, placeholder, error, type = "text", disabled = false, ...rest }, ref) => {
        const baseClasses = "form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500";
        const disabledClasses = disabled ? "bg-slate-100 dark:bg-zink-600" : "dark:bg-zink-700";
        const errorClasses = error ? "border-red-500" : "";

        return (
            <div className="mb-3">
                <label className="inline-block mb-2 text-base font-medium">{label}</label>
                {type === "textarea" ? (
          <textarea
            className={`${baseClasses} ${disabledClasses} ${errorClasses}`}
            placeholder={placeholder}
            ref={ref}
            disabled={disabled}
            {...rest}
          />
        ) : (
          <input
            type={type}
            className={`${baseClasses} ${disabledClasses} ${errorClasses}`}
            placeholder={placeholder}
            ref={ref}
            disabled={disabled}
            {...rest}
          />
        )}
                {error && <div className="mt-1 text-sm text-red-500">{error}</div>}
            </div>
        );
    }
);

export const HrmSearch = React.forwardRef(
    ({ placeholder, label, ...rest }, ref) => {
        return (
          <>
          <label className="inline-block mb-2 text-base font-medium">{label}</label>
        <div className="relative">
          <input
            type="text"
            className="ltr:pl-8 rtl:pr-8 search form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
            placeholder="Search for ..."
            autocomplete="off"
            ref={ref}
            {...rest}
          />
          <Search
            className="inline-block size-4 absolute ltr:left-2.5 rtl:right-2.5 top-2.5 text-slate-500 dark:text-zink-200 fill-slate-100 dark:fill-zink-600"
          />
        </div>
        </>
        )
    }
);

export const HrmFormSelect = React.forwardRef(
    ({ label, options = [], placeholder = "Select", error, disabled = false, isMulti=false, onChange, ...rest }, ref) => {
      const baseClasses = "form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500";
      const disabledClasses = disabled ? "bg-slate-100 dark:bg-zink-600" : "dark:bg-zink-700";
      const errorClasses = error ? "border-red-500" : "";
  
      return (
        <div className="mb-3">
          <label className="inline-block mb-2 text-base font-medium">{label}</label>
          <select
            className={`${baseClasses} ${disabledClasses} ${errorClasses} ${isMulti ? "multi-select-dropdown" : ""}`}
            ref={ref}
            disabled={disabled}
            onChange={onChange}
            multiple={isMulti}
            {...rest}
          >
            <option value="">{placeholder}</option>
            {/* {!isMulti && <option value="">{placeholder}</option>} */}
            {options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
          {error && <div className="mt-1 text-sm text-red-500">{error}</div>}
        </div>
      );
    }
  );