import './tailwind.css';
import './custom.css';
import { AppRoutes } from './constants/appRoutes';
import React from "react";
import { BrowserRouter } from "react-router-dom";
import { AppWrapper } from './app-wrapper';

const App = () => {
  return (
    <React.Fragment>
      <BrowserRouter>
        <AppWrapper>
          <AppRoutes />
        </AppWrapper>
      </BrowserRouter>
    </React.Fragment>
  );
};

export default App;