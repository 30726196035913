import AWS from "aws-sdk";

AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  region: process.env.REACT_APP_AWS_REGION
});

const s3 = new AWS.S3();

export const uploadImageToS3 = async (file) => {

    let extension = file.type?.split("/")[1];
    const fileName = `${new Date().getTime()}.${extension}`;
    
  const params = {
    Bucket: process.env.REACT_APP_AWS_BUCKET,
    Key: `${process.env.REACT_APP_AWS_FOLDER}${fileName}`, // File name you want to save as in S3
    Body: file,
    ContentType: file.type,
  };

  try {
    const data = await s3.upload(params).promise();
    return fileName;
  } catch (err) {
    console.error("Upload Error", err);
    throw err;
  }
};

// folder : /Hrms/profile_photo/