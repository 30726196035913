import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { HrmFormInput } from "../components/hrm_input";
import { Editor } from 'primereact/editor';
import { useBlog } from "../../contexts";
import { uploadImageToS3 } from "../../utils/fileUpload";
import HrmLoader from "../components/hrm_loader";
        


const AddBlog = () => {

    // const [text, setText] = useState();
    // const [metaText, setMetaText] = useState();
    

    const { onAddBlog, blogLoader } = useBlog();
    
    const {
        handleSubmit,
        register,
        reset, 
        watch
      } = useForm();
      
      const selectedThumbNail = watch("thumb_nail")?.[0];
      const previewThumbNail = selectedThumbNail ? URL.createObjectURL(selectedThumbNail) : null;
      const selectedImage = watch("image")?.[0];
      const previewImage = selectedImage ? URL.createObjectURL(selectedImage) : null;

      const onSubmit = async (values) => {

        const thumbnail = values?.thumb_nail[0];
        const image = values?.image[0];

        const s3thumbnail = await uploadImageToS3(thumbnail);
        const s3image = await uploadImageToS3(image);

        const payload = {
            ...values,
            // long_desc: text,
            // meta_desc: metaText,
            image: s3image,
            thumb_nail: s3thumbnail
        }
        
        await onAddBlog(payload);
      }

  const handleReset = () => {
    reset();
  }
    return (
        <div>
            <div className="col-span-12 md:order-1 xl:col-span-8 2xl:col-span-6">
        <h5 className="mb-2">Add Blog</h5>
    </div>
            <div className="grid grid-cols-1 xl:grid-cols-12 gap-x-5">
                    <div className="xl:col-span-12">
                        <div className="card">
                            <div className="card-body">
                              {
                                blogLoader ? (
                                  <HrmLoader />
                                ) : (
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="flex gap-3">
                                        <div className="flex-1">
                                            <HrmFormInput label={"Title"} {...register("title")} />
                                            <HrmFormInput label={"Short Description"} {...register("short_desc")} />
                                            <HrmFormInput label={"Long Description"} type="textarea" {...register("long_desc")} />
                                            {/* <p>Long Description</p>
                                            <Editor value={text} onTextChange={(e) => setText(e.htmlValue)} style={{ height: '320px' }} />  */}
                                            <HrmFormInput label={"Thumbnail"} type="file" accept="image/*" {...register("thumb_nail")} /> 
                                            { previewThumbNail && (
                                                <div>
                                                <p>Selected Thumbnail Preview:</p>
                                                <img
                                                  src={previewThumbNail}
                                                  alt="Preview"
                                                  style={{ maxWidth: "150px", maxHeight: "100px" }}
                                                />
                                              </div>
                                            ) 
                                            }
                                            <HrmFormInput label={"Image"} type="file" accept="image/*" {...register("image")} />
                                            { previewImage && (
                                                <div>
                                                <p>Selected Image Preview:</p>
                                                <img
                                                  src={previewImage}
                                                  alt="Preview"
                                                  style={{ maxWidth: "350px", maxHeight: "300px" }}
                                                />
                                              </div>
                                            ) 
                                            }
                                        <HrmFormInput label={"Meta tag"} {...register("meta_tag")} />
                                        <HrmFormInput label={"Meta Description"} type="textarea" {...register("meta_desc")} />
                                        {/* <p>Meta Description</p>
                                        <Editor value={metaText} onTextChange={(e) => setMetaText(e.htmlValue)} style={{ height: '320px' }} /> */}
                                          
                                        </div>
                                        
                                    </div>                                    
                                    <div className="flex justify-center gap-2 mt-4">
                                        <button type="reset" className="text-red-500 bg-white btn hover:text-red-500 hover:bg-red-100 focus:text-red-500 focus:bg-red-100 active:text-red-500 active:bg-red-100 dark:bg-zink-700 dark:hover:bg-red-500/10 dark:focus:bg-red-500/10 dark:active:bg-red-500/10" onClick={handleReset}>Reset</button>
                                        <button type="submit" className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20">Add Blog</button>
                                    </div>
                                </form>
                                )
                              }
                            </div>
                        </div>
                    </div>

                    
                    
                </div>
        </div>
    )
};

export default AddBlog;