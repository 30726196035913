import axios from "axios";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API 
});

instance.interceptors.request.use((config) => {
  const apiKey = process.env.REACT_APP_API_KEY 

  if (apiKey) {
    config.headers['api-key'] = apiKey;
  }

  return config;
});

export default instance;
