import React, { useEffect } from "react";
import { useBlog } from "../../contexts";
import { useParams } from "react-router-dom";
import HrmLoader from "../components/hrm_loader";
        
const ViewBlog = () => {

    const { getBlog, blogLoader, blogDetail } = useBlog();

    const { id } = useParams();

    useEffect(() => {
        getBlog(id)
    }, [getBlog, id]);

    return (
                            <div className="card">
                            <div className="card-body border border-gray-300 dark:border-gray-700 rounded-lg p-4">
                                {
                                    blogLoader ? ( <HrmLoader /> ) : (
                                        <>
                                <h3>{blogDetail?.title}</h3><br />
                                <div className="card-body border border-gray-300 dark:border-gray-700 rounded-lg p-4 mb-3">
                                    <strong>Short Description:</strong> <p>{blogDetail?.short_desc}</p>
                                </div>
                                <div className="card-body border border-gray-300 dark:border-gray-700 rounded-lg p-4 mb-3">
                                    <strong>Long Description:</strong> <p>{blogDetail?.long_desc}</p>
                                </div>
                                <div className="card-body border border-gray-300 dark:border-gray-700 rounded-lg p-4 mb-3">
                                    <strong>Meta Tag:</strong> <p>{blogDetail?.meta_tag}</p>
                                </div>
                                <div className="card-body border border-gray-300 dark:border-gray-700 rounded-lg p-4 mb-3">
                                    <strong>Thumbnail:</strong> <img src={blogDetail?.thumb_nail} alt="Blog" className="img-fluid" style={{ height: '100px', width: '100px' }} />
                                </div>
                                <div className="card-body border border-gray-300 dark:border-gray-700 rounded-lg p-4 mb-3">
                                    <strong>Date:</strong> <p>{blogDetail?.created_at}</p>
                                </div>
                                <div className="card-body border border-gray-300 dark:border-gray-700 rounded-lg p-4 mb-3">
                                    <strong>Status:</strong> <p>{blogDetail?.is_active === 1 ? 'Active' : 'Inactive'}</p>
                                </div>
                                <div className="card-body border border-gray-300 dark:border-gray-700 rounded-lg p-4 mb-3">
                                    <strong>Image:</strong> <img src={blogDetail?.image} alt="Blog" className="img-fluid" style={{ height: '250px', width: '300px' }} />
                                </div>
                                        </>
                                    )
                                }
                            </div>
                        </div>
    )
};

export default ViewBlog;