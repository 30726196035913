import React from "react";

const Dashboard = () => {
  
  return (
    <div className="grid grid-cols-12 2xl:grid-cols-12 gap-x-5">
          <div className="col-span-12 md:order-1 xl:col-span-8 2xl:col-span-6">
        <h5 className="mb-2">Welcome to TTPro 🎉</h5>   
        
      </div>
    </div>
  );
};

export default Dashboard;
