import React from "react";

const HrmLoader = () => {
  return (
    <div className="inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
        <div className="loader"></div>
    </div>
    // <div class="inline-flex rounded-full opacity-75 size-4 animate-ping bg-sky-400"></div>
  );
};

export default HrmLoader;