import * as Icons from "lucide-react";
import { DEFAULT_ACTION, VIEW } from "../../constants/actions";
import { Link } from "react-router-dom";

export const HrmActions = ({
  onSelectAction,
  row,
  isDefault = false,
  view = false,
}) => {
  return (
    <div>
      {isDefault === true && (
        <div className="flex gap-2">
          {DEFAULT_ACTION.map((item) => {
            const IconComponent = Icons[item.icon];
            return (
              <Link
                key={item.value}
                eventKey={item.value}
                onClick={() => onSelectAction(row, item.value)}
                // onClick={onEditClick}
                className={`flex items-center justify-center transition-all duration-200 ease-linear rounded-md size-8 bg-${item.color}-100 text-${item.color}-500 hover:text-${item.color}-100 hover:bg-${item.color}-500`}
              >
                <IconComponent className="size-4" />
              </Link>
            );
          })}
        </div>
      )}
      {view === true && (
        <div>
          {VIEW.map((item) => {
            const IconComponent = Icons[item.icon];
            return (
              <Link
                key={item.value}
                eventKey={item.value}
                onClick={() => onSelectAction(row, item.value)}
                className={`flex items-center justify-center transition-all duration-200 ease-linear rounded-md size-8 bg-${item.color}-100 text-${item.color}-500 hover:text-${item.color}-100 hover:bg-${item.color}-500`}
              >
                <IconComponent className="size-4" />
              </Link>
            );
          })}
        </div>
      )}
    </div>
  );
};
