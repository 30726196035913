import React, { useEffect, useState } from "react";
import NavBar from "../components/hrm_navbar";
import NewSideBar from "../components/newSidebar";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


const TestLayout = ({ children }) => {

  const [open, setOpen] = useState(true);

  const year = new Date().getFullYear();
  useEffect(() => {
    // Set body classes when the component mounts
    document.body.className = "text-base bg-body-bg text-body font-public dark:text-zink-100 dark:bg-zink-800 group-data-[skin=bordered]:bg-body-bordered group-data-[skin=bordered]:dark:bg-zink-700";

    // Cleanup function to reset body classes on unmount
    return () => {
      document.body.className = "";
    };
  }, []);

  return (
    <div>
            <NewSideBar open={open} />
          <NavBar  open={open} setOpen={setOpen} />
          <div className="relative min-h-screen group-data-[sidebar-size=sm]:min-h-sm" style={{ marginLeft: open ? "12rem" : "5.25rem" }}>
              <div className="group-data-[sidebar-size=lg]:rtl:md:mr-vertical-menu group-data-[sidebar-size=md]:ltr:ml-vertical-menu-md group-data-[sidebar-size=md]:rtl:mr-vertical-menu-md group-data-[sidebar-size=sm]:ltr:ml-vertical-menu-sm group-data-[sidebar-size=sm]:rtl:mr-vertical-menu-sm pt-[calc(theme('spacing.header')_*_1)] pb-[calc(theme('spacing.header')_*_0.8)] px-4 group-data-[navbar=bordered]:pt-[calc(theme('spacing.header')_*_1.3)] group-data-[navbar=hidden]:pt-0 group-data-[layout=horizontal]:mx-auto group-data-[layout=horizontal]:max-w-screen-2xl group-data-[layout=horizontal]:px-0 group-data-[layout=horizontal]:group-data-[sidebar-size=lg]:ltr:md:ml-auto group-data-[layout=horizontal]:group-data-[sidebar-size=lg]:rtl:md:mr-auto group-data-[layout=horizontal]:md:pt-[calc(theme('spacing.header')_*_1.6)] group-data-[layout=horizontal]:px-3 group-data-[layout=horizontal]:group-data-[navbar=hidden]:pt-[calc(theme('spacing.header')_*_0.9)]">
                <div className="container-fluid group-data-[content=boxed]:max-w-boxed mx-auto pt-4">
                  {children}
                  <ToastContainer />
                </div>
              </div>
              <footer
          className="ltr:md:left-vertical-menu rtl:md:right-vertical-menu group-data-[sidebar-size=md]:ltr:md:left-vertical-menu-md group-data-[sidebar-size=md]:rtl:md:right-vertical-menu-md group-data-[sidebar-size=sm]:ltr:md:left-vertical-menu-sm group-data-[sidebar-size=sm]:rtl:md:right-vertical-menu-sm absolute right-0 bottom-0 px-4 h-14 group-data-[layout=horizontal]:ltr:left-0 group-data-[layout=horizontal]:rtl:right-0 left-0 border-t py-3 flex items-center dark:border-zink-600"
          style={{marginLeft: "-17rem"}}>
          <div
            className="group-data-[layout=horizontal]:mx-auto group-data-[layout=horizontal]:max-w-screen-2xl w-full"
          >
            <div
              className="grid items-center grid-cols-1 lg:grid-cols-2 text-slate-400 dark:text-zink-200 ltr:lg:text-left rtl:lg:text-right"
            >
              <div>
                {year}
                © TTPro.
              </div>
            </div>
          </div>
        </footer>
            </div>
        </div>
  )
};

export default TestLayout;

