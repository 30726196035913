import { componentRoutes } from "../constants/componentRoutes"

export const sidebarLinks = [
    {
        label: 'Dashboard',
        icon: 'MonitorDot', // Replace with actual icon class
        link: componentRoutes.adminDashboard,
        children: []
    },
    {
        label: 'Blogs',
        icon: 'MonitorDot', // Replace with actual icon class
        link: componentRoutes.blog,
        children: []
    },
    {
        label: 'Add Blog',
        icon: 'MonitorDot', // Replace with actual icon class
        link: componentRoutes.addBlog,
        children: []
    },
    
];