import React from "react";
import { Routes, Route } from "react-router-dom";
import { pageRoutes } from "./pageRoutes";
import { PrivateRoute } from "./privateRoutes";

export const AppRoutes = () => {
    return (
        <Routes>
            {pageRoutes.map((route, index) => (
                <Route
                path={route.path}
                // element={route.component}
                element={<PrivateRoute route={route} />}
                key={index}
                />
              ))}
        </Routes>
    );
  };

  