import { Eye } from "lucide-react";
import { HrmActions } from "../../pages/components/hrm_actions";

export const adminBlogColumns = ({ onSelectAction }) => {
  return [
    {
      Header: "Sr. No.",
      accessor: "idd", // Assuming you set 'idd' correctly in the data
      Cell: ({ row }) => {
        return row.original.idd; // Return the idd value
      },
    },
    {
        Header: "Title",
        accessor: (row) => {
          return row?.title || "N/A";
        },
      },
    {
        Header: "Meta Tag",
        accessor: (row) => {
          return row?.meta_tag || "N/A";
        },
    },
    
      {
        Header: "View Blog",
        accessor: (row) => {
          return (
            <div className="text-left">
              <HrmActions
                onSelectAction={onSelectAction}
                row={row}
                view={true}
              />
            </div>
          );
        },
      },
    {
      Header: "Actions",
      accessor: (row) => {
        return (
          <div className="text-left">
            <HrmActions
              onSelectAction={onSelectAction}
              row={row}
              isDefault={true}
            />
          </div>
        );
      },
    },
  ];
};
